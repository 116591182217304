import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useToggle } from 'react-use';

import PermissionModel from '@/models/Permission';
import RoutePaths from '@/models/RoutePaths';
import { UIProperties } from '@/models/UIProperties';

import {
  actions as dnsDeviceActions,
  selectors as dnsDeviceSelectors,
} from '@/redux/api/dnsDevice';

import { Breadcrumb } from '+components/Breadcrumb';
import ConfirmModal from '+components/ConfirmModal';
import EditPageAuditLogTabs from '+components/EditPageAuditLogTabs';
import Field from '+components/form/FinalForm/Field';
import { normalizeSelectValue } from '+components/form/Normalizers';
import SelectField from '+components/form/SelectField';
import { TextField } from '+components/form/TextField';
import ToggleField from '+components/form/Toggle/ToggleField';
import {
  validateForNotOnlyNumbers,
  validateRequired,
} from '+components/form/Validators';
import FormWizard, { Step } from '+components/FormWizard';
import { usePageTabs } from '+components/PageTabs';
import usePermissions from '+hooks/usePermissions';
import useUIProperty from '+hooks/useUIProperty';

// Ivan Seredkin: For now by DNS Device we understand Infoblox devices
//  That's why there is only one type of device
const typeOptions = ['infoblox'];
const defaultDevice = {
  name: '',
  type: 'infoblox',
  site: '',
  enabled: true,
};

const DnsDeviceForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { deviceid } = useParams();
  const editing = !!deviceid;

  const [, activePageTab] = usePageTabs();
  const permissions = usePermissions(PermissionModel.Resources.device.value);

  const [isCopyMode, setCopyMode] = useToggle(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const error = useSelector(dnsDeviceSelectors.getError);
  const isFetching = useSelector(dnsDeviceSelectors.isFetching);

  const device =
    useSelector(dnsDeviceSelectors.getDNSDevice(deviceid)) || defaultDevice;
  const create = !editing || isCopyMode;
  const canManage = create ? permissions?.create : permissions?.update;
  const canRemove = editing && !isCopyMode && permissions?.delete;

  const onSubmit = useCallback(
    (values) => {
      setIsSubmitting(activePageTab?.id);

      if (isCopyMode) {
        // prefilled edit form treated as add form
        delete values.id;
        dispatch(dnsDeviceActions.createDNSDevice(values));
      } else if (deviceid) {
        // normal edit form
        dispatch(dnsDeviceActions.updateDNSDevice(values));
      } else {
        // normal add form
        dispatch(dnsDeviceActions.createDNSDevice(values));
      }
    },
    [isCopyMode, deviceid, activePageTab?.id],
  );

  const onCancel = useCallback(() => {
    navigate(`${RoutePaths.sources}/add`);
  }, []);

  const onDone = useCallback(() => {
    navigate(`${RoutePaths.sources}`);
  }, []);

  const onSecondary = useCallback(() => {
    setCopyMode(!isCopyMode);
  }, [isCopyMode]);

  const additionalActions = useMemo(
    () =>
      editing && permissions?.create
        ? [
            {
              text: `Switch to ${isCopyMode ? 'Update' : 'Create'}`,
              onClick: onSecondary,
            },
          ]
        : undefined,
    [permissions, isCopyMode, onSecondary, editing],
  );

  const onDeleteModal = useCallback(() => {
    setShowDeleteModal((prevValue) => !prevValue);
  }, []);

  const onDelete = useCallback(() => {
    dispatch(dnsDeviceActions.removeDNSDevice(deviceid));
    onDone();
  }, [deviceid, onDone]);

  useEffect(() => {
    if (deviceid) {
      dispatch(dnsDeviceActions.fetchDNSDevice(deviceid));
    }
  }, [deviceid]);

  useEffect(() => {
    if (!isSubmitting || isFetching) {
      return;
    }

    if (isSubmitting !== activePageTab?.id) {
      return;
    }

    if (!error) {
      onDone();
    } else {
      setIsSubmitting(false);
    }
  }, [isSubmitting, isFetching, error, activePageTab?.id, onDone]);

  const [, setMasqueradeUrl] = useUIProperty(UIProperties.masqueradeUrl);
  useEffect(() => {
    if (editing) {
      setMasqueradeUrl(`${RoutePaths.sources}`);
    }
    return () => {
      setMasqueradeUrl(null);
    };
  }, []);

  return (
    <EditPageAuditLogTabs
      showTabs={editing}
      auditNqlQuery={`class == device && original_id == ${deviceid}`}
      breadcrumb={<Breadcrumb title={`${editing ? 'Edit' : 'Add'} Device`} />}
    >
      <FormWizard
        initialValues={device}
        onSubmit={onSubmit}
        onCancel={onCancel}
        additionalActions={additionalActions}
        confirmButtonText={create ? 'Create' : 'Update'}
        loading={isFetching}
        disabled={isFetching || !canManage}
        deleteButtonText="Delete Device"
        onDelete={onDeleteModal}
        deleteButtonHidden={!editing || isCopyMode}
        deleteButtonDisabled={!canRemove}
      >
        <Step>
          <Field
            name="type"
            label="Type"
            component={SelectField}
            options={typeOptions}
            parse={normalizeSelectValue}
            validate={validateRequired}
            disabled
            required
          />

          <Field
            name="name"
            label="Name"
            component={TextField}
            validate={[validateForNotOnlyNumbers, validateRequired]}
            maxLength={100}
            helperText="Name of the device. Recommend using a FQDN, e.g. router1.site.company.com"
            disabled={!canManage}
            required
          />

          <Field
            name="site"
            label="Site"
            component={TextField}
            type="text"
            helperText="The site identifier for this device"
            validate={validateRequired}
            disabled={!canManage}
            required
          />

          <Field
            name="enabled"
            checkedLabel="Enabled"
            component={ToggleField}
            type="checkbox"
            disabled={!canManage}
            helperText="Enable or disable this DNS device"
          />
        </Step>
      </FormWizard>

      {showDeleteModal && (
        <ConfirmModal
          item={device?.name}
          onToggle={onDeleteModal}
          onConfirm={onDelete}
          isOpen
        />
      )}
    </EditPageAuditLogTabs>
  );
};

export default DnsDeviceForm;
