import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import get from 'lodash.get';

import RoutePaths from '@/models/RoutePaths';

import { SelectColumnFilter } from '../Filters';
import { FilterOperator, withAutoRemove } from '../FilterTypeFactories';
import { autoRemoveByOperator } from '../ReactTable/utils';
import { BaseColumnFactory } from './BaseColumnFactory';
import { getRowOriginal } from './utils';

const sortAuthorColumn = (accessor) => (rowA, rowB) => {
  const originalA = getRowOriginal(rowA);
  const originalB = getRowOriginal(rowB);
  const valueA = originalA?.user?.name || get(originalA, accessor) || '';
  const valueB = originalB?.user?.name || get(originalB, accessor) || '';
  return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
};

/**
 * Author column factory.
 * @param {object} props
 * @param {string} [props.emailProp] - Email property name.
 * @param {string} [props.profileEmail] - Profile email.
 * @returns {*}
 * @constructor
 */
export const AuthorColumnFactory = (props) => {
  const { accessor, profileEmail, ...rest } = props;

  const fixedAccessor = accessor || 'email';

  return BaseColumnFactory({
    width: 220,
    accessor: fixedAccessor,
    Header: 'Author',
    Cell: ({ row, value }) =>
      useMemo(() => {
        const original = getRowOriginal(row);
        return !original?.user ? (
          value
        ) : (
          <Link to={`${RoutePaths.users}/${original?.user?.id}`}>
            {original?.user?.name}
          </Link>
        );
      }, [getRowOriginal(row)]),
    Filter: SelectColumnFilter({
      fixedOptions: ['all', 'Mine', 'Not Mine'],
      enableLikeFilter: true,
    }),
    filter: withAutoRemove((rows, _, filterValue) => {
      if (autoRemoveByOperator(filterValue)) {
        return rows;
      }

      if (filterValue.operator === FilterOperator.equal) {
        if (filterValue.value === 'Mine') {
          return rows.filter(
            ({ original }) =>
              !original.system && get(original, fixedAccessor) === profileEmail,
          );
        }

        if (filterValue.value === 'Not Mine') {
          return rows.filter(
            ({ original }) =>
              original.system || get(original, fixedAccessor) !== profileEmail,
          );
        }
      }

      const normalizedFilterValue = String(filterValue.value).toLowerCase();
      return rows.filter(
        ({ original }) =>
          !original.system &&
          ((get(original, fixedAccessor) || '')
            .toLowerCase()
            .includes(normalizedFilterValue) ||
            original.user?.name?.toLowerCase().includes(normalizedFilterValue)),
      );
    }, autoRemoveByOperator),
    sortType: sortAuthorColumn(fixedAccessor),

    ...rest,
  });
};
