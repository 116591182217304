import { cloneElement, Fragment, useCallback, useMemo } from 'react';
import { useToggle } from 'react-use';

import { useFlag } from '@unleash/proxy-client-react';
import styled from 'styled-components';

import DividerOriginal from '@mui/material/Divider';

import { ContextTypes } from '@/models/ContextTypes';
import FeatureFlags from '@/models/FeatureFlags';
import PermissionModel from '@/models/Permission';
import RoutePaths from '@/models/RoutePaths';

import Button from '+components/Button';
import { ActionsContainer, Col, Row } from '+components/Layout';
import { usePageTabs } from '+components/PageTabs';
import PluginCard from '+components/PluginCard';
import Tag from '+components/Tag';
import useMaxSources from '+hooks/useMaxSources';
import usePermissions from '+hooks/usePermissions';
import useProviderClassifications from '+hooks/useProviderClassifications';

import { SourceTypeKeys, SOURCETYPES } from '../../utils/sourceTypes';
import CheckDevicesModal from './CheckDevicesModal';

const advancedTypes = new Set(['AWS Kinesis', 'Azure NSG']);

const Divider = styled(DividerOriginal).attrs(() => ({
  textAlign: 'left',
}))`
  text-transform: uppercase;

  &:before {
    width: 0;
  }

  &:after {
    width: 100%;
  }

  span {
    opacity: 0.5;
  }
`;

const AddSource = () => {
  const [, , pageTabMethods] = usePageTabs();

  const [hideAdvancedTypes, toggleShowAdvancedTypes] = useToggle(true);

  const classifications = useProviderClassifications();

  const isDNSDevicesEnabled = useFlag(FeatureFlags.dnsDevices);
  const devicePermissions = usePermissions(
    PermissionModel.Resources.device.value,
  );
  const cloudPermissions = usePermissions(
    PermissionModel.Resources.cloud_provider.value,
  );

  const trafficCategories = useMemo(() => {
    const result = {
      flow: [],
      dns: [],
    };
    Object.values(SOURCETYPES).forEach((item) => {
      if (item.type === 'device' && item.context === 'dns') {
        return;
      }
      const keys = Object.keys(
        classifications?.[item.flowtype]?.[item.flowresource] || {
          flow: null,
        },
      );
      keys.forEach((key) => {
        result[key].push(item);
      });
    });

    if (isDNSDevicesEnabled) {
      result.dns.unshift(SOURCETYPES[SourceTypeKeys.dnsDevice]);
    }

    return result;
  }, [classifications, isDNSDevicesEnabled]);

  const [showCheckDevicesModal, toggleCheckDevicesModal] = useToggle(false);

  const { enforceMaxSources, sourcesRemaining } = useMaxSources();

  const onAddDeviceClick = useCallback(
    (trafficType) => () => {
      if (trafficType === ContextTypes.flow) {
        pageTabMethods.updateActive(`${RoutePaths.sourcesDevices}/add`);
      } else {
        pageTabMethods.updateActive(`${RoutePaths.sourcesDnsDevices}/add`);
      }
    },
    [],
  );

  const onAddVpcClick = useCallback(
    (flowType, flowResource, trafficType) => () => {
      pageTabMethods.updateActive(
        `${RoutePaths.sourcesCloudProviders}/add/${flowType}/${flowResource}/${trafficType}`,
      );
    },
    [],
  );

  return (
    <Col gap="10px">
      <ActionsContainer>
        <Button
          onClick={() => toggleCheckDevicesModal()}
          disabled={!devicePermissions?.create}
        >
          Check for devices
        </Button>
        <Button onClick={toggleShowAdvancedTypes} variant="outlined">
          {hideAdvancedTypes ? 'Show Advanced' : 'Hide Advanced'}
        </Button>
      </ActionsContainer>

      {enforceMaxSources && (
        <Row gap="10px">
          <Tag color="secondary" outlined={false}>
            {sourcesRemaining} Remaining
          </Tag>
        </Row>
      )}

      {Object.entries(trafficCategories).map(([trafficType, sources]) => {
        return (
          <Fragment key={trafficType}>
            <Divider>{trafficType}</Divider>
            <Row gap="10px">
              {sources.map((item) => {
                if (hideAdvancedTypes && advancedTypes.has(item.label)) {
                  return null;
                }
                const name =
                  trafficType === ContextTypes.flow
                    ? item.label
                    : item.labelDns || item.label;
                const icon = item.icon
                  ? cloneElement(item.icon, { size: 40 })
                  : item.icon;
                const disabled =
                  item.type === 'device'
                    ? !devicePermissions?.create
                    : !cloudPermissions?.create;
                const onClick =
                  item.type === 'device'
                    ? onAddDeviceClick(trafficType)
                    : onAddVpcClick(
                        item.flowtype,
                        item.flowresource,
                        trafficType,
                      );
                return (
                  <PluginCard
                    key={`${trafficType}-${item.label}`}
                    group={trafficType}
                    name={name}
                    icon={icon}
                    caption={`Add ${item.label} Traffic Source`}
                    disabled={disabled}
                    onClick={onClick}
                  />
                );
              })}
            </Row>
          </Fragment>
        );
      })}

      {showCheckDevicesModal && (
        <CheckDevicesModal
          isOpen={showCheckDevicesModal}
          toggleModal={toggleCheckDevicesModal}
        />
      )}
    </Col>
  );
};

export default AddSource;
