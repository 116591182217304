import { useMemo } from 'react';

import capitalize from 'lodash.capitalize';

import CheckCircleIcon from 'mdi-react/CheckCircleIcon';

import PermissionModel from '@/models/Permission';

import { HeaderSubheaderCell } from '+components/Table/Cells';
import {
  AuthorColumnFactory,
  BaseColumnFactory,
  EnabledDisabledColumnFactory,
  MenuColumnFactory,
} from '+components/Table/Columns';
import { SelectColumnFilter } from '+components/Table/Filters';
import {
  autoRemoveIfAll,
  withAutoRemove,
} from '+components/Table/FilterTypeFactories';
import { Palette } from '+theme';

const manageActionsArr = [
  PermissionModel.Actions.fetch,
  PermissionModel.Actions.create,
  PermissionModel.Actions.update,
  PermissionModel.Actions.delete,
];

const getPermissionValue = (permissions) => {
  let expected = 0;
  let current = 0;

  (permissions || []).forEach(({ resource, ...item }) => {
    const resourceMeta = PermissionModel.Resources[resource];
    const excludeActions = new Set(resourceMeta?.excludeActions || []);

    Object.entries(item).forEach(([key, value]) => {
      if (excludeActions.has(key)) {
        return;
      }

      expected += 1;
      current += value;
    });
  });

  if (current === expected) {
    return expected !== 0 ? 2 : 0;
  }

  return current > 0 ? 1 : 0;
};

const accessorFactory =
  (group, canManageResellerParams) =>
  ({ permissions }) =>
    getPermissionValue(
      permissions.filter(({ resource }) => {
        const resourceMeta = PermissionModel.Resources[resource];

        if (
          !resourceMeta ||
          (resourceMeta.resellersOnly && !canManageResellerParams)
        ) {
          return false;
        }

        return resourceMeta.group === group;
      }),
    );

const filterOptions = (group, canManageResellerParams) =>
  Object.values(PermissionModel.Resources)
    .filter((resource) => resource.group === group)
    .reduce((acc, item) => {
      if (item.mergeWithResource) {
        return acc;
      }

      if (item.resellersOnly && !canManageResellerParams) {
        return acc;
      }

      return [
        ...acc,
        ...manageActionsArr.reduce(
          (actions, action) =>
            item.excludeActions?.includes(action)
              ? actions
              : [
                  ...actions,
                  {
                    value: `${item.value}-${action}`,
                    label: capitalize(action),
                    group: item.label,
                    inputValue: `${item.label} - ${capitalize(action)}`, // this will be displayed in select input
                  },
                ],
          [],
        ),
      ];
    }, []);

const TernaryCell = ({ value }) =>
  useMemo(() => {
    const fixed = typeof value === 'boolean' ? +value * 2 : value;

    return (
      fixed > 0 && (
        <CheckCircleIcon
          size={22}
          style={{
            color: fixed === 2 ? Palette.success : Palette.gray3,
          }}
        />
      )
    );
  }, [value]);

const permissionGroupColumnFactory = (canManageResellerParams) => (group) =>
  BaseColumnFactory({
    accessor: accessorFactory(group, canManageResellerParams),
    id: `permissions.${group}`,
    Header: group,
    getCellProps: () => ({ style: { justifyContent: 'center' } }),
    Cell: TernaryCell,
    sortType: 'number',
    Filter: SelectColumnFilter({
      fixedOptions: [
        { value: 'all', label: 'All' },
        ...filterOptions(group, canManageResellerParams),
      ],
      sort: false,
      selectProps: {
        groupBy: (option) => option.group,
      },
    }),
    filter: withAutoRemove((rows, _, filterValue) => {
      if (autoRemoveIfAll(filterValue)) {
        return rows;
      }

      const [filterValueResource, filterValueAction] =
        filterValue.value.split('-');

      return rows.filter(({ original: { permissions: value } }) =>
        value.some(
          (val) =>
            val.resource === filterValueResource && val[filterValueAction],
        ),
      );
    }, autoRemoveIfAll),
  });

export const getColumns = ({
  canManageResellerParams,
  showSubscription,
  cxActionMenu,
  profileEmail,
}) =>
  [
    BaseColumnFactory({
      accessor: 'name',
      Header: 'Name / Desc',
      width: 300,
      Cell: HeaderSubheaderCell({
        propHeader: 'name',
        propSubheader: 'description',
      }),
      realAccessor: ['name', 'description'],
    }),
    canManageResellerParams &&
      EnabledDisabledColumnFactory({
        accessor: 'canMasquerade',
        Header: 'Masquerading',
        Cell: TernaryCell,
        width: 'auto',
      }),
    EnabledDisabledColumnFactory({
      accessor: 'canSendFlow',
      Header: 'Send NetoFlow',
      Cell: TernaryCell,
      width: 'auto',
    }),
    EnabledDisabledColumnFactory({
      accessor: 'canSendDns',
      Header: 'Send NetoDNS',
      Cell: TernaryCell,
      width: 'auto',
    }),
    EnabledDisabledColumnFactory({
      accessor: 'canFetchAuditLog',
      Header: 'View Audit Logs',
      Cell: TernaryCell,
      width: 'auto',
    }),
    showSubscription &&
      EnabledDisabledColumnFactory({
        accessor: 'canManageSubscription',
        Header: 'Manage Subscription',
        Cell: TernaryCell,
        width: 'auto',
      }),
    ...Object.values(PermissionModel.ResourceGroups).map(
      permissionGroupColumnFactory(canManageResellerParams),
    ),
    AuthorColumnFactory({
      accessor: 'createdBy',
      profileEmail,
      width: 'auto',
      minWidth: 120,
    }),
    MenuColumnFactory({ cxActionMenu }),
  ].filter(Boolean);
