import PropTypes from 'prop-types';

import { Stack } from '@mui/material';

import { ActionsContainer, LayoutSizes, Row } from '+components/Layout';
import { BackToButton } from '+components/PageTabs';

import { PageTitle } from './PageTitle';

export const PageHeader = (props) => {
  const { title, actions, showBackButton, marginBottom } = props;

  return (
    <Row item marginBottom={marginBottom}>
      <Stack direction="row" gap={LayoutSizes.groupGap} alignItems="center">
        {showBackButton && <BackToButton />}
        <PageTitle>{title}</PageTitle>
        {actions && <ActionsContainer mb={0}>{actions}</ActionsContainer>}
      </Stack>
    </Row>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  actions: PropTypes.node,
  showBackButton: PropTypes.bool,
  marginBottom: PropTypes.string,
};

PageHeader.defaultProps = {
  actions: null,
  showBackButton: false,
  marginBottom: undefined,
};
