import { useCallback } from 'react';

import ArrowBackIcon from 'mdi-react/ArrowBackIcon';

import IconButton from '+components/IconButton';

import { usePageTabs } from './usePageTabs';

export const BackToButton = () => {
  const [, active, { back }] = usePageTabs();

  const onClick = useCallback(() => {
    back();
  }, [back]);

  return (
    !!active.previous && (
      <IconButton onClick={onClick}>
        <ArrowBackIcon />
      </IconButton>
    )
  );
};
